import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Card,
    Flex,
    Heading,
    Image,
    Text,
    View,
    Link,
    Divider,
    Loader,
    useAuthenticator,
    Alert
} from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession, AuthSession } from 'aws-amplify/auth';
import { useCustomSignOut } from '../hooks/useCustomSignOut';
import { isSameDomain, getCookeName, getRootDomain } from '../utils/auth';
import Cookies from 'js-cookie';
import { EmailChangeForm } from './EmailChangeForm';
import { PasswordChangeForm } from './PasswordChangeForm';
import { LanguageSwitcher } from './LanguageSwitcher';

// メールアドレス変更機能の有効/無効フラグ
// この変数を true に変更するだけでメールアドレス変更機能が復活します
const EMAIL_CHANGE_ENABLED = false;

interface UserInfo {
    username: string;
    userId: string;
    email: string;
    sub: string;
    idTokenPayload: any;
    identities?: string;
}

export const AuthenticatedHome: React.FC = () => {
    const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
    const customSignOut = useCustomSignOut();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [loading, setLoading] = useState(true);
    const [redirectUri, setRedirectUri] = useState<string | null>(null);
    const [showEmailChangeForm, setShowEmailChangeForm] = useState(false);
    const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);

    // Googleユーザーかどうかを判定する関数
    const isGoogleUser = useCallback((user: UserInfo | null): boolean => {
        if (!user || !user.identities) return false;

        try {
            const identities = JSON.parse(user.identities);
            return identities.some((identity: any) =>
                identity.providerType === 'Google' ||
                identity.providerName === 'Google'
            );
        } catch (e) {
            console.error('Error parsing identities:', e);
            return false;
        }
    }, []);

    const renewCookies = useCallback(async () => {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens?.idToken?.toString();
            const accessToken = session.tokens?.accessToken?.toString();

            if (!idToken || !accessToken) {
                console.error('Failed to get tokens');
                return false;
            }

            const cookieOptions: Cookies.CookieAttributes = {
                secure: window.location.protocol === 'https:',
                sameSite: 'strict',
                domain: getRootDomain(window.location.hostname),
                expires: 7
            };

            Cookies.set(getCookeName(session as AuthSession, 'idToken'), idToken, cookieOptions);
            Cookies.set(getCookeName(session as AuthSession, 'accessToken'), accessToken, cookieOptions);
            Cookies.set(getCookeName(session as AuthSession, 'LastAuthUser'), session.tokens?.idToken?.payload?.sub || '', cookieOptions);

            return true;
        } catch (error) {
            console.error('Error renewing cookies:', error);
            if (error instanceof Error) {
                console.error('Error name:', error.name);
                console.error('Error message:', error.message);
                console.error('Error stack:', error.stack);
            }
            return false;
        }
    }, []);

    const refreshUserInfo = useCallback(async () => {
        if (authStatus === 'authenticated' && user) {
            try {
                setLoading(true);
                const session = await fetchAuthSession();
                const attributes = await fetchUserAttributes();
                setUserInfo({
                    username: user.username || '',
                    userId: user.userId || '',
                    email: attributes.email || '',
                    sub: session.tokens?.idToken?.payload?.sub || '',
                    idTokenPayload: session.tokens?.idToken?.payload || {},
                    identities: attributes.identities
                });
                await renewCookies();
            } catch (error) {
                console.error('Error refreshing user info:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [authStatus, user, renewCookies]);

    useEffect(() => {
        const initializeUserInfo = async () => {
            await refreshUserInfo();

            const storedRedirectUri = localStorage.getItem('redirect_uri');
            if (storedRedirectUri && isSameDomain(storedRedirectUri)) {
                setRedirectUri(storedRedirectUri);
            }
        };

        initializeUserInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, authStatus]);

    const handleToggleEmailChangeForm = useCallback(() => {
        setShowEmailChangeForm(!showEmailChangeForm);
        if (showPasswordChangeForm) setShowPasswordChangeForm(false);
    }, [showEmailChangeForm, showPasswordChangeForm]);

    const handleTogglePasswordChangeForm = useCallback(() => {
        setShowPasswordChangeForm(!showPasswordChangeForm);
        if (showEmailChangeForm) setShowEmailChangeForm(false);
    }, [showPasswordChangeForm, showEmailChangeForm]);

    const handleEmailChangeSuccess = useCallback(async () => {
        await refreshUserInfo();
        setShowEmailChangeForm(false);
    }, [refreshUserInfo]);

    const handlePasswordChangeSuccess = useCallback(() => {
        setShowPasswordChangeForm(false);
    }, []);

    const handleSignOut = async () => {
        try {
            const result = await customSignOut();
            localStorage.removeItem('redirect_uri');
            return result;
        } catch (error) {
            console.error('Error during sign out:', error);
            return { success: false, error };
        }
    };

    const handleRedirect = () => {
        if (redirectUri) {
            window.location.href = redirectUri;
            localStorage.removeItem('redirect_uri');
        }
    };

    if (loading) {
        return (
            <View padding="1rem">
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <Loader size="large" />
                </Flex>
            </View>
        );
    }

    return (
        <View
            padding="1rem"
            maxWidth="480px"
            margin="0 auto"
        >
            <Card>
                <Flex direction="column" alignItems="center">
                    <Link
                        href="https://www.yolo-japan.com"
                        isExternal={false}
                        style={{ textDecoration: 'none' }}
                    >
                        <Image
                            alt="YOLO JAPAN logo"
                            src="https://www.yolo-japan.com/assets/images/base/logo_header.png?v202407251030"
                            width="180px"
                            marginBottom="1rem"
                        />
                    </Link>
                    <Heading level={3} fontSize="1.5rem" textAlign="center">
                        <FormattedMessage id="welcomeMessage" defaultMessage="Welcome to YOLO JAPAN" />
                    </Heading>

                    {/* 言語切替コンポーネント */}
                    <View marginTop="1rem">
                        <LanguageSwitcher />
                    </View>
                </Flex>

                <Divider marginTop="1.5rem" marginBottom="1.5rem" />

                {userInfo && (
                    <Flex direction="column" gap="1rem">
                        <Heading level={5} fontSize="1.1rem">
                            <FormattedMessage id="userInfoHeading" defaultMessage="User Information" />
                        </Heading>

                        <Flex direction="column" gap="0.5rem">
                            <Text fontSize="0.9rem" style={{ wordBreak: 'break-all' }}>
                                <FormattedMessage id="emailLabel" defaultMessage="Email" />: {userInfo.email}
                            </Text>

                            {isGoogleUser(userInfo) && EMAIL_CHANGE_ENABLED ? (
                                <Alert variation="info" hasIcon={true} fontSize="0.8rem" padding="0.5rem">
                                    <FormattedMessage
                                        id="googleEmailChangeInfo"
                                        defaultMessage="You are signed in with Google. To change your email address, please update it in your Google account settings."
                                    />
                                </Alert>
                            ) : EMAIL_CHANGE_ENABLED && (
                                <Button
                                    onClick={handleToggleEmailChangeForm}
                                    variation="link"
                                    size="small"
                                    padding="0"
                                    fontSize="0.9rem"
                                >
                                    <FormattedMessage
                                        id={showEmailChangeForm ? "hideEmailChangeForm" : "showEmailChangeForm"}
                                        defaultMessage={showEmailChangeForm ? "Cancel" : "Change Email"}
                                    />
                                </Button>
                            )}
                        </Flex>

                        {showEmailChangeForm && userInfo.email && !isGoogleUser(userInfo) && EMAIL_CHANGE_ENABLED && (
                            <EmailChangeForm
                                currentEmail={userInfo.email}
                                onSuccess={handleEmailChangeSuccess}
                            />
                        )}

                        {/* パスワード変更セクション */}
                        {isGoogleUser(userInfo) ? (
                            <Alert variation="info" hasIcon={true} fontSize="0.8rem" padding="0.5rem">
                                <FormattedMessage
                                    id="googlePasswordChangeInfo"
                                    defaultMessage="You are signed in with Google. To change your password, please update it in your Google account settings."
                                />
                            </Alert>
                        ) : (
                            <Flex direction="column" gap="0.5rem" marginTop="0.5rem">
                                <Button
                                    onClick={handleTogglePasswordChangeForm}
                                    variation="link"
                                    size="small"
                                    padding="0"
                                    fontSize="0.9rem"
                                >
                                    <FormattedMessage
                                        id={showPasswordChangeForm ? "hidePasswordChangeForm" : "showPasswordChangeForm"}
                                        defaultMessage={showPasswordChangeForm ? "Cancel" : "Change Password"}
                                    />
                                </Button>

                                {showPasswordChangeForm && (
                                    <PasswordChangeForm onSuccess={handlePasswordChangeSuccess} />
                                )}
                            </Flex>
                        )}

                        <Text
                            variation="tertiary"
                            fontSize="0.75rem"
                            marginTop="0.5rem"
                            style={{ wordBreak: 'break-all' }}
                        >
                            <FormattedMessage id="userIdLabel" defaultMessage="User ID" />: {userInfo.userId}
                        </Text>
                    </Flex>
                )}

                <Flex justifyContent="center" marginTop="2rem" gap="1rem" direction={{ base: 'column', medium: 'row' }}>
                    {redirectUri && (
                        <Button onClick={handleRedirect} variation="primary" width="100%">
                            <FormattedMessage id="returnToAppButton" defaultMessage="Return to Site" />
                        </Button>
                    )}
                    <Button onClick={handleSignOut} variation="primary" width="100%">
                        <FormattedMessage id="signOutButton" defaultMessage="Sign Out" />
                    </Button>
                </Flex>
            </Card>
        </View>
    );
};