import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    View,
    Heading,
    Text,
    Loader,
    Flex,
    useTheme
} from '@aws-amplify/ui-react';

export const ConfirmUserPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { tokens } = useTheme();

    useEffect(() => {
        const fetchHtmlContent = async () => {
            const client_id = searchParams.get('client_id');
            const user_name = searchParams.get('user_name');
            const confirmation_code = searchParams.get('confirmation_code');

            if (!client_id || !user_name || !confirmation_code) {
                setError('Missing required parameters');
                setLoading(false);
                return;
            }

            const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
            if (!cognitoDomain) {
                setError('Cognito domain is not configured');
                setLoading(false);
                return;
            }

            const url = `${cognitoDomain}/confirmUser?client_id=${client_id}&user_name=${user_name}&confirmation_code=${confirmation_code}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include', // クッキーを含める
                    headers: {
                        'Accept': 'text/html',
                    },
                });

                if (!response.ok) {
                    setError(`HTTP error! status: ${response.status}`);
                    return;
                }
                const html = await response.text();
                setHtmlContent(html);
            } catch (err) {
                setError('Failed to fetch HTML content');
                console.error('Error fetching HTML:', err);
            } finally {
                setLoading(false);
            }
        };

        // fetchHtmlContentを呼び出し、戻り値のPromiseをawaitする
        // Promise ignoredの警告を解消するために、明示的に戻り値のPromiseを処理
        fetchHtmlContent().catch(err => {
            console.error("Unhandled error in fetchHtmlContent:", err);
            setError('An unexpected error occurred');
            setLoading(false);
        });
    }, [searchParams]);

    if (loading) {
        return (
            <View
                padding={tokens.space.large}
                maxWidth="480px"
                margin="0 auto"
            >
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <Loader size="large" />
                </Flex>
            </View>
        );
    }

    if (error) {
        return (
            <View
                padding={tokens.space.large}
                maxWidth="480px"
                margin="0 auto"
            >
                <Heading level={3}>Error</Heading>
                <Text>{error}</Text>
            </View>
        );
    }

    return (
        <View
            padding={tokens.space.large}
            maxWidth="480px"
            margin="0 auto"
        >
            <Heading level={3}>Confirmation Page Content</Heading>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </View>
    );
};