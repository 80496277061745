import { useCallback } from 'react';
import { useLanguage } from '../components/LanguageProvider';
import { signIn, signUp, fetchAuthSession, type SignInInput, type SignUpInput, type SignUpOutput } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
import Cookies from 'js-cookie';
import { getCookeName, isSameDomain, getRootDomain } from '../utils/auth';

// Amplify UIのhub eventsを利用して、エラーを通知する
import { Hub } from 'aws-amplify/utils';

export const useAuthServices = () => {
    const { language } = useLanguage();

    const handlePostAuthSuccess = useCallback(async () => {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens?.idToken?.toString();
            const accessToken = session.tokens?.accessToken?.toString();
            const sub = session.tokens?.idToken?.payload?.sub;

            if (!idToken || !accessToken || !sub) {
                console.error('Failed to get tokens');
                return;
            }

            const cookieOptions: Cookies.CookieAttributes = {
                secure: window.location.protocol === 'https:',
                sameSite: 'strict',
                domain: getRootDomain(window.location.hostname),
                expires: 7
            };

            Cookies.set(getCookeName(session, 'idToken'), idToken, cookieOptions);
            Cookies.set(getCookeName(session, 'accessToken'), accessToken, cookieOptions);
            Cookies.set(getCookeName(session, 'LastAuthUser'), sub, cookieOptions);

            const urlParams = new URLSearchParams(window.location.search);
            const redirectUri = urlParams.get('redirect_uri');
            if (redirectUri && isSameDomain(redirectUri)) {
                window.location.href = redirectUri;
            }
        } catch (error) {
            console.error('Error during post-auth handling:', error);
        }
    }, []);

    const handleSignIn = useCallback(async (formData: SignInInput) => {
        const { username, password } = formData;
        if (!username || !password) {
            console.error('Username and password are required');
            // エラーをHubに発行して、UIに通知する
            Hub.dispatch('auth', {
                event: 'signIn_failure',
                data: new Error('Username and password are required')
            });

            // 代わりにサインイン失敗を示す結果オブジェクトを返す
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'DONE',
                }
            };
        }

        try {
            const result = await signIn({
                username,
                password,
                options: {
                    authFlowType: 'USER_PASSWORD_AUTH'
                }
            });

            if (result.isSignedIn) {
                await handlePostAuthSuccess();
            }
            return result;
        } catch (error) {
            console.error('Error during sign in:', error);
            // 例外をcatchする代わりに、エラー情報をHubに発行
            Hub.dispatch('auth', {
                event: 'signIn_failure',
                data: error
            });

            // 代わりにサインイン失敗を示す結果オブジェクトを返す
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'DONE',
                }
            };
        }
    }, [handlePostAuthSuccess]);

    const handleSignUp = useCallback(async (formData: SignUpInput): Promise<SignUpOutput> => {
        try {
            const { username, password, options } = formData;

            const userAttributes = {
                ...(options?.userAttributes || {}),
                locale: language
            };

            const signUpResult = await signUp({
                username,
                password,
                options: {
                    ...options,
                    userAttributes,
                },
            });

            if (signUpResult.isSignUpComplete) {
                // サインアップ後の自動サインイン
                try {
                    await handleSignIn({ username, password });
                } catch (signInError) {
                    console.error('Auto sign-in failed after sign-up:', signInError);
                }
            }

            return signUpResult;
        } catch (error) {
            console.error('Error during sign up:', error);
            // 例外を再スローする代わりに、エラー情報をHubに発行
            Hub.dispatch('auth', {
                event: 'signUp_failure',
                data: error
            });

            // TypeScriptの型エラーを回避するためにダミー結果を返す
            // @ts-ignore - 一時的に型チェックを無視
            return {
                isSignUpComplete: false,
                nextStep: {
                    signUpStep: 'DONE',
                }
            };
        }
    }, [language, handleSignIn]);

    const validateCustomSignUp = useCallback(async (formData: { acknowledgement: any }) => {
        if (!formData.acknowledgement) {
            return {
                acknowledgement: I18n.get('termsAndConditionsError'),
            };
        }
        return undefined;
    }, []);

    return {
        handleSignUp,
        handleSignIn,
        validateCustomSignUp,
    };
};