import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Flex,
    Heading,
    View,
    useTheme,
    Alert,
    PasswordField
} from '@aws-amplify/ui-react';
import { updatePassword } from 'aws-amplify/auth';

export const PasswordChangeForm: React.FC<{onSuccess: () => void}> = ({ onSuccess }) => {
    const { tokens } = useTheme();
    const intl = useIntl();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(e.target.value);
        setError(null);
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        setError(null);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        setError(null);
    };

    const validatePassword = (): boolean => {
        // パスワードの最小長さ
        if (newPassword.length < 8) {
            setError(intl.formatMessage({
                id: 'passwordLengthError',
                defaultMessage: 'Password must be at least 8 characters long'
            }));
            return false;
        }

        // 数字を含むか
        if (!/\d/.test(newPassword)) {
            setError(intl.formatMessage({
                id: 'passwordNumberError',
                defaultMessage: 'Password must contain at least one number'
            }));
            return false;
        }

        // 小文字を含むか
        if (!/[a-z]/.test(newPassword)) {
            setError(intl.formatMessage({
                id: 'passwordLowercaseError',
                defaultMessage: 'Password must contain at least one lowercase letter'
            }));
            return false;
        }

        // 大文字を含むか
        if (!/[A-Z]/.test(newPassword)) {
            setError(intl.formatMessage({
                id: 'passwordUppercaseError',
                defaultMessage: 'Password must contain at least one uppercase letter'
            }));
            return false;
        }

        // パスワード確認が一致するか
        if (newPassword !== confirmPassword) {
            setError(intl.formatMessage({
                id: 'passwordMatchError',
                defaultMessage: 'Passwords do not match'
            }));
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!currentPassword || !newPassword || !confirmPassword) {
            setError(intl.formatMessage({
                id: 'allFieldsRequired',
                defaultMessage: 'All fields are required'
            }));
            setLoading(false);
            return;
        }

        if (!validatePassword()) {
            setLoading(false);
            return;
        }

        try {
            await updatePassword({
                oldPassword: currentPassword,
                newPassword: newPassword
            });

            setSuccess(intl.formatMessage({
                id: 'passwordChangeSuccess',
                defaultMessage: 'Password has been successfully updated'
            }));

            // フォームをリセット
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

            // 成功コールバック
            setTimeout(() => {
                onSuccess();
            }, 2000);
        } catch (err) {
            console.error('Error updating password:', err);
            if (err instanceof Error) {
                if (err.name === 'NotAuthorizedException') {
                    setError(intl.formatMessage({
                        id: 'incorrectPassword',
                        defaultMessage: 'Current password is incorrect'
                    }));
                } else {
                    setError(err.message);
                }
            } else {
                setError(String(err));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <View
            as="div"
            padding={tokens.space.medium}
            backgroundColor={tokens.colors.background.secondary}
            borderRadius={tokens.radii.medium}
        >
            <Heading level={4} padding={tokens.space.small}>
                <FormattedMessage id="changePasswordHeading" defaultMessage="Change Password" />
            </Heading>

            {error && (
                <Alert variation="error" isDismissible={true} hasIcon={true}>
                    {error}
                </Alert>
            )}

            {success && (
                <Alert variation="success" isDismissible={true} hasIcon={true}>
                    {success}
                </Alert>
            )}

            <form onSubmit={handleSubmit}>
                <Flex direction="column" gap={tokens.space.medium}>
                    <PasswordField
                        label={<FormattedMessage id="currentPassword" defaultMessage="Current Password" />}
                        name="currentPassword"
                        value={currentPassword}
                        onChange={handleCurrentPasswordChange}
                        required
                    />

                    <PasswordField
                        label={<FormattedMessage id="newPassword" defaultMessage="New Password" />}
                        name="newPassword"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        required
                    />

                    <PasswordField
                        label={<FormattedMessage id="confirmPassword" defaultMessage="Confirm New Password" />}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />

                    <Button
                        type="submit"
                        variation="primary"
                        isLoading={loading}
                        loadingText={intl.formatMessage({ id: "processing", defaultMessage: "Processing..." })}
                    >
                        <FormattedMessage id="changePassword" defaultMessage="Change Password" />
                    </Button>
                </Flex>
            </form>
        </View>
    );
};