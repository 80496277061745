import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useSearchParams, Navigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import {
    Authenticator,
    CheckboxField,
    Image,
    Text,
    useAuthenticator,
    useTheme,
    View,
    Link,
    ThemeProvider
} from '@aws-amplify/ui-react';
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils';

// Import the Amplify configuration
import { config } from './config/amplify-config';

// Import the Google Tag Manager component
import { GoogleTagManager } from './components/GoogleTagManager';
import { GTM_ID } from './config/gtm-config';

// Import custom components
import { LanguageProvider, useLanguage } from './components/LanguageProvider';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { AuthenticatedHome } from './components/AuthenticatedHome';
import { ConfirmUserPage } from './components/ConfirmUserPage';
import { useAuthServices } from './hooks/useAuthServices';

// Configure Amplify
Amplify.configure(config);

// Authenticator components
const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Link
                    href="https://www.yolo-japan.com"
                    isExternal={false}
                    style={{
                        textDecoration: 'none',
                        display: 'inline-block'
                    }}
                >
                    <Image
                        alt="YOLO JAPAN logo"
                        src="https://www.yolo-japan.com/assets/images/base/logo_header.png?v202407251030"
                    />
                </Link>
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; YOLO JAPAN CORPORATION.
                </Text>
            </View>
        );
    },

    SignUp: {
        FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
                <>
                    <Authenticator.SignUp.FormFields />
                    <Link href="https://www.yolo-japan.com/ja/terms_of_service/" isExternal>
                        <FormattedMessage id="termsAndConditionsLink" />
                    </Link>
                    <Link href="https://www.yolo-japan.co.jp/privacy_policy/" isExternal>
                        <FormattedMessage id="privacyPolicyLink" />
                    </Link>
                    <CheckboxField
                        errorMessage={validationErrors.acknowledgement as string}
                        hasError={!!validationErrors.acknowledgement}
                        name="acknowledgement"
                        value="yes"
                        label={<FormattedMessage id="termsAndConditions" />}
                    />
                </>
            );
        },
    },
};

// Sign Up Component
const SignUpContent = () => {
    const { language } = useLanguage();
    const { handleSignUp, validateCustomSignUp } = useAuthServices();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check and update redirect_uri in localStorage
        const redirectUri = searchParams.get('redirect_uri');
        if (redirectUri) {
            localStorage.setItem('redirect_uri', redirectUri);
        }
    }, [searchParams]);

    // Authenticator servicesをラップしてTypeScriptエラーを回避
    const services = {
        // TypeScriptの型エラーを回避するためにany型を使用
        // @ts-ignore
        handleSignUp,
        validateCustomSignUp
    };

    return (
        <View>
            <LanguageSwitcher />
            <Authenticator
                key={language}
                initialState="signUp"
                loginMechanisms={['email']}
                socialProviders={['google']}
                components={components}
                services={services}
            >
                {() => (
                    <AuthenticatedHome />
                )}
            </Authenticator>
        </View>
    );
};

// Sign In Component
const SignInContent = () => {
    const { language } = useLanguage();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check and update redirect_uri in localStorage
        const redirectUri = searchParams.get('redirect_uri');
        if (redirectUri) {
            localStorage.setItem('redirect_uri', redirectUri);
        }

        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    console.log('Sign in successful');
                    break;
                case 'signInWithRedirect_failure':
                    console.error('Sign in failed:', payload.data);
                    // Amplify v6ではpayload.dataが必ずしもErrorインスタンスではないため型安全に処理
                    const error = payload.data as any;
                    if (error && typeof error === 'object') {
                        console.error('Error details:', {
                            name: error.name,
                            message: error.message,
                            stack: error.stack
                        });
                    }
                    break;
                case 'tokenRefresh':
                    console.log('Token refreshed successfully');
                    break;
                case 'tokenRefresh_failure':
                    console.error('Token refresh failed:', payload.data);
                    const refreshError = payload.data as any;
                    if (refreshError && typeof refreshError === 'object') {
                        console.error('Error details:', {
                            name: refreshError.name,
                            message: refreshError.message,
                            stack: refreshError.stack
                        });
                    }
                    break;
            }
        });

        return () => {
            unsubscribe();
        };
    }, [searchParams]);

    return (
        <View>
            <LanguageSwitcher />
            <Authenticator
                key={language}
                initialState="signIn"
                loginMechanisms={['email']}
                socialProviders={['google']}
                components={components}
            >
                {() => (
                    <AuthenticatedHome />
                )}
            </Authenticator>
        </View>
    );
};

// Reset Password Component
const ForgotPasswordContent = () => {
    const { language } = useLanguage();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check and update redirect_uri in localStorage
        const redirectUri = searchParams.get('redirect_uri');
        if (redirectUri) {
            localStorage.setItem('redirect_uri', redirectUri);
        }
    }, [searchParams]);

    return (
        <View>
            <LanguageSwitcher />
            <Authenticator
                key={language}
                initialState="forgotPassword"
                loginMechanisms={['email']}
                socialProviders={['google']}
                components={components}
            >
                {() => (
                    <AuthenticatedHome />
                )}
            </Authenticator>
        </View>
    );
};

// Default Content (Sign In) with redirect capability
const DefaultContent = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    if (authStatus === 'authenticated') {
        return <AuthenticatedHome />;
    }

    return <SignInContent />;
};

// Main App Component
const App = () => {
    return (
        <ThemeProvider>
            <LanguageProvider>
                <Authenticator.Provider>
                    <BrowserRouter>
                        <GoogleTagManager gtmId={GTM_ID} />
                        <Routes>
                            <Route path="/confirm-user" element={<ConfirmUserPage />} />
                            <Route path="/signup" element={<SignUpContent />} />
                            <Route path="/signin" element={<SignInContent />} />
                            <Route path="/forgot-password" element={<ForgotPasswordContent />} />
                            <Route path="/" element={<DefaultContent />} />
                            {/* Redirect old paths to new ones */}
                            <Route path="/login" element={<Navigate to="/signin" replace />} />
                            <Route path="/register" element={<Navigate to="/signup" replace />} />
                            <Route path="/reset-password" element={<Navigate to="/forgot-password" replace />} />
                        </Routes>
                    </BrowserRouter>
                </Authenticator.Provider>
            </LanguageProvider>
        </ThemeProvider>
    );
}

export default App;