import {useCallback} from 'react';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {fetchAuthSession, signOut} from 'aws-amplify/auth';
import Cookies from 'js-cookie';
import {getCookeName, getRootDomain} from '../utils/auth';

export const useCustomSignOut = () => {
    const { signOut: amplifySignOut } = useAuthenticator();

    return useCallback(async () => {
        try {
            console.log('Custom Sign out');
            const session = await fetchAuthSession();

            // Cookieの共通オプション
            const cookieOptions: Cookies.CookieAttributes = {
                secure: window.location.protocol === 'https:',
                sameSite: 'strict',
                domain: getRootDomain(window.location.hostname),
            };

            // Cookieを削除
            Cookies.remove(getCookeName(session, 'idToken'), cookieOptions);
            Cookies.remove(getCookeName(session, 'accessToken'), cookieOptions);

            // リダイレクトURIを指定してサインアウト
            const redirectUri = process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT || window.location.origin;

            // Amplifyの標準サインアウト処理を呼び出す
            await signOut({
                global: true,
                oauth: {
                    redirectUrl: redirectUri
                }
            });

            amplifySignOut();
            return {success: true};
        } catch (error) {
            console.error('Error during sign out:', error);
            // エラーを再スローせず、エラー情報を含むオブジェクトを返す
            return {
                success: false,
                error
            };
        }
    }, [amplifySignOut]);
};